import { createRouter, createWebHistory } from 'vue-router';
import { useCounterStore } from '@/store/index.js';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      name: 'Landing',
      path: '/',
      component: () => import('@/views/LandingPage/index'),
      children: [
        {
          name: 'delete',
          path: 'delete',
          component: () => import('@/views/Pages/delete'),
        },
        {
          name: 'home',
          path: '',
          component: () => import('@/views/LandingPage/home'),
        },
        {
          name: 'aboutPage',
          path: 'aboutPage',
          component: () => import('@/views/LandingPage/aboutPage/aboutPage'),
        },
        {
          name: 'Q&APage',
          path: 'Q&APage',
          component: () => import('@/views/LandingPage/Q&APage/Q&APage'),
        },
        {
          name: 'apps',
          path: 'apps',
          component: () => import('@/views/LandingPage/apps'),
        },
        {
          name: 'policy',
          path: 'policy',
          component: () => import('@/views/LandingPage/policy'),
        },
        {
          name: 'external_member_id',
          path: 'external_member/:id',
          component: () => import('@/views/LandingPage/external_member'),
          props: true,
        },
        {
          name: 'external_member',
          path: 'external_member',
          component: () => import('@/views/LandingPage/external_member'),
        },
      ],
    },

    {
      path: '/:catchAll(.*)',
      name: 'error',
      component: () => import('@/views/Pages/index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/Pages/errorPage'),
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.name === 'aboutPage' && window.zE) {
    window.zE('messenger', 'hide'); // Hide widget on specific route
  } else if (window.zE) {
    window.zE('messenger', 'show'); // Show widget on other routes
  }
  next();
});
export default router;

<template>
  <SplashScreenComponent v-if="showSplash" @remove-splash="onSplashRemoved" />
  <router-view v-else />
</template>

<script>
import SplashScreenComponent from './components/splashScreen.vue';

export default {
  components: {
    SplashScreenComponent,
  },
  data() {
    return {
      showSplash: true,
    };
  },
  created() {
    this.getLang();
  },
  // computed: {

  //   font() {
  //   if (this.$i18n.locale === "ar") {
  //     return "'somer'";
  //   } else {
  //     return "'nato'";
  //   }
  // },
  // },

  // watch: {
  //   font(newValue, oldValue) {
  //     document.documentElement.style.setProperty("--font", newValue);
  //   },
  //   "$i18n.locale"(newValue, oldValue) {
  //   },
  // },
  methods: {
    getLang() {
      if (localStorage.getItem('lang')) {
        this.$i18n.locale = localStorage.getItem('lang');
      } else {
        this.$i18n.locale = 'ar';
        localStorage.setItem('lang', this.$i18n.locale);
      }
    },
    onSplashRemoved() {
      this.showSplash = false;
    },
  },
};
</script>
<style lang="scss">
body {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.v-btn {
  letter-spacing: 0 !important;
}
:root {
  --font: 'somer';
}
@font-face {
  font-family: 'somer';
  src: local('somer'),
    url(./assets/fonts/TTF/Standard/SomarSans-Regular.ttf) format('truetype');
}
// @font-face {
//   font-family: "nato";
//   src: local("nato"),
//     url(./assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic/NotoSansArabic-Regular.ttf)
//       format("truetype");
// }
* {
  font-family: 'somer';
}
// html,
// body {
//   font-family: var(--font) !important;
// }
// * {
//   font-family: var(--font);
// }
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313942;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1fa35e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1fa35e;
}
.slider {
  display: block;
  /* other styles */
}

/* For mobile screens */
@media only screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}
</style>
